import React from "react"
import styles from "../common.module.scss"
import { motion } from "framer-motion"
import ProjectStatusBar from "../common/ProjectStatusBar"
import DeskEstateSplash from "./../../../images/projects/LMJ/desk_estatesplash.png"
import DeskMenuSS from "./../../../images/projects/LMJ/desk_menu.png"
import DeskNavSS from "./../../../images/projects/LMJ/desk_nav.png"
import MobServicesSS from "./../../../images/projects/LMJ/mob_services.png"
import MobWatchRepair from "./../../../images/projects/LMJ/mob_watchrepair.png"
import PromoCard from "./../../../images/projects/LMJ/promocard.png"

export default function index() {
  return (
    <motion.div
      initial={{ opacity: 0 }}
      animate={{ opacity: 1 }}
      exit={{ opacity: 0 }}
      key="workKioskVenu"
    >
      <div className={styles.devProjectPage}>
        <header className={styles.pageHeader}>
          <h1 className={styles.pageTitle}>Louis Martin Jewelers</h1>

          <ProjectStatusBar
            statusText="Project complete"
            demoUrl="https://louismartin.com"
          />
        </header>

        <main className={styles.mainContent}>
          {/* Specifications / requirements */}
          <section className={styles.mainSection}>
            <h2>2017-2020</h2>
            <p>
              Worked in a small dev team to tackle software customizations for
              Louis Martin Jewelers in NYC. The work was multi-faceted, touching
              on aspects of UI/UX design, frontend development, e-commerce
              integrations with retail point-of-sale, inventory management and
              more.
            </p>
          </section>

          {/* Scope of work */}
          <section className={styles.mainSection}>
            <h2>Scope of work</h2>
            <p>
              The work at Louis Martin began with an overhaul of the existing
              customer-facing site. After exploring options and committing to
              the Shopify platform, work began on creating the Shopify theme
              files and integrating existing POS automation with web inventory
              to prepare for ecommerce sales.
            </p>
            <p>
              Next, work began on creating a self-hosted Shopify app that
              automated data entry for jewelry inventory, including
              automatically adding corresponding Shopify collection metafields
              and product tags and metafields.
            </p>

            <h6>Tech stack:</h6>
            <ul>
              <li>CMS / File storage: Shopify</li>
              <li>Templating: Shopify Liquid</li>
              <li>Shopify Admin App: ReactJS/Polaris</li>
            </ul>

            {/* What will I be building? What will it include/not include
          what are the foreseen costs/dev time associated with the requirements */}
            {/* Sample pics of final design */}
          </section>

          {/* Progress */}
          <section className={styles.mainSection}>
            <h2>Work samples 📷</h2>
            <p>
              Following are some screenshots of website layouts that I
              developed.
            </p>

            <div className={styles.galleryContainer}>
              <figure>
                <img
                  src={DeskEstateSplash}
                  className={styles.galleryImage}
                  alt="LMJ screenshot of Estate Jewelry page"
                />
                <figcaption>Estate Jewelry section</figcaption>
              </figure>

              <div className={styles.galleryRowLayout}>
                <figure>
                  <img
                    src={DeskMenuSS}
                    className={styles.galleryImageLong}
                    alt="LMJ screenshot of site menu in desktop view"
                  />
                  <figcaption>Site menu (desktop)</figcaption>
                </figure>
                <figure>
                  <img
                    src={MobServicesSS}
                    className={styles.galleryImageLong}
                    alt="LMJ screenshot of Services page in mobile layout"
                  />
                  <figcaption>Services page (mobile layout)</figcaption>
                </figure>
              </div>
              <figure>
                <img
                  src={DeskNavSS}
                  className={styles.galleryImage}
                  alt="LMJ screenshot of site nav bar in desktop view"
                />
                <figcaption>Site nav bar (desktop)</figcaption>
              </figure>
              <figure>
                <img
                  src={MobWatchRepair}
                  className={styles.galleryImageLong}
                  alt="LMJ screenshot of Watch Repair page in mobile view"
                />
                <figcaption>Watch Repair page (mobile)</figcaption>
              </figure>
            </div>
          </section>
        </main>
      </div>
    </motion.div>
  )
}
